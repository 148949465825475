import { Injectable } from '@angular/core';
import {
  DDocSigningStatus,
  Signer,
} from '../../modules/loa/shared/loa.model';
import { LoaLevel } from '../../store/memo/memo.model';
import { featureFlag } from '../../../environments/environment';
import { DropdownItem } from '../models/common.model';
import { MemoDetail } from 'src/app/modules/memos/model/memo.model';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class DdocService {
  signingSequence: string[];
  signingDropdownAllowance: SigningDropdownAllowance[] = [];
  constructor(private store: Store) {}

  /**
   * Check array of signing type to return their status.
   * @returns The status of signing type array that contain a first index of each signing type.
   */
  checkSigningSequenceStatus(
    signingSequence: string[],
    sealEnabled: boolean,
  ): SigningSequenceStatus {
    let certifyIndex: number = null;
    let digitalSignStartIndex: number = null;
    try {
      signingSequence.forEach((signingType, index) => {
        if (signingType === 'normal') {
          return;
        }
        if (digitalSignStartIndex == null) {
          digitalSignStartIndex = index;
        }
        if (['certified', 'certified-locked'].includes(signingType)) {
          if (sealEnabled) {
            throw {
              error: 'seal document cannot use certify in loa.',
            };
          }
          if (certifyIndex == null) {
            certifyIndex = index;
          } else {
            throw {
              error: 'there are many loa level that select certify.',
            };
          }
        }
      });
    } catch (error) {
      return {
        ...error,
        certifyIndex,
        digitalSignStartIndex,
      };
    }
    return {
      certifyIndex,
      digitalSignStartIndex,
    };
  }

  /**
   * Generate the allowance of signing type dropdown from the sequence of signing type.
   * @returns The allowance of each type according to the sequence of signing type.
   */
  checkSigningDropdownAllowance(
    signingSequence: string[],
  ): SigningDropdownAllowance[] {
    const result = [];
    const memoDetail: MemoDetail = this.store.selectSnapshot(
      (state) => state.memoCreationData,
    );
    const sealEnabled: boolean = memoDetail?.ddoc_sealing_enable;
    const addTimestamp: boolean = memoDetail?.add_timestamp;

    const status = this.checkSigningSequenceStatus(
      signingSequence,
      sealEnabled,
    );

    for (let index = 0; index < signingSequence.length; index++) {
      const allowance = {
        allowCertify: true,
        allowCertifyWithLock: true,
        allowNormalSign: true,
        allowSignedBy: true,
      };

      /**
       * In case before this member there is using cert 26, 28
       * then don't allow normal signing and certify
       * Ex. LOA [normal, normal, sign, sign]
       *                                 ^-- this item cannot use normal, certify
       */
      if (
        status.digitalSignStartIndex != null &&
        index > status.digitalSignStartIndex
      ) {
        allowance.allowNormalSign = false;
        allowance.allowCertify = false;
        allowance.allowCertifyWithLock = false;
      }

      // certify lock is for last step only. Ex. LOA [normal, normal, certify-lock]
      if (index != signingSequence.length - 1) {
        allowance.allowCertifyWithLock = false;
      }

      /**
       * In case after this member there is using normal sign
       * then don't allow digital signing
       * Ex. LOA [normal, normal, sign, sign]
       *             ^-- this item cannot use sign, certify
       */
      if (
        index < signingSequence.length - 1 &&
        signingSequence[index + 1] === 'normal'
      ) {
        allowance.allowSignedBy = false;
        allowance.allowCertify = false;
        allowance.allowCertifyWithLock = false;
      }

      if (sealEnabled) {
        // if seal is enabled, system not allow to certify in all levels.
        allowance.allowCertify = false;
        allowance.allowCertifyWithLock = false;

        // if seal is enabled, system allow 2 options
        //  1. use normal in all levels
        //  2. use sign-by in all levels
        if (index > 0) {
          if (signingSequence[0] != 'normal') {
            allowance.allowNormalSign = false;
          }
          if (signingSequence[0] != 'signed-by') {
            allowance.allowSignedBy = false;
          }
        }
        if (index == 0 && signingSequence.length > 1) {
          if (signingSequence[1] != 'normal') {
            allowance.allowNormalSign = false;
          }
          if (signingSequence[1] != 'signed-by') {
            allowance.allowSignedBy = false;
          }
        }
      }

      // if enable timestamp, disable certify lock in all levels.
      if (addTimestamp) {
        allowance.allowCertifyWithLock = false;
      }

      /** In case there is existence of certify then don't allow certify more */
      if (
        status.certifyIndex != null &&
        status.certifyIndex !== index
      ) {
        allowance.allowCertify = false;
      }

      result.push(allowance);
    }

    return result;
  }

  /**
   * Get the signing type from each `Level` of `LOA`.
   * By checking from ddoc status of the first signer in `Level`.
   */
  getSigningTypeSequence(
    loaLevel: LoaLevel<Signer>[],
  ): SigningType[] {
    return loaLevel?.map((level) => {
      const signer = level.members[0] as Signer;
      if (signer.ddoc_locked_mode) {
        return 'certified-locked';
      } else if (signer.ddoc_certified_mode) {
        return 'certified';
      } else if (level.ddoc_enable) {
        return 'signed-by';
      } else {
        return 'normal';
      }
    });
  }

  refreshSigningDropdownAllowance(): SigningDropdownAllowance[] {
    this.signingDropdownAllowance =
      this.checkSigningDropdownAllowance(this.signingSequence);
    return this.signingDropdownAllowance;
  }

  /** The function for filling default singing status */
  setDefaultSigningStatus(
    signer: Partial<Signer>,
    ddocLevelEnable: boolean,
  ): void {
    const defaultSigningStatus: DDocSigningStatus = {
      ddoc_use_26: ddocLevelEnable,
      ddoc_use_28: false,
      ddoc_certified_mode: false,
      ddoc_locked_mode: false,
    };
    Object.assign(signer, defaultSigningStatus);
  }

  setSigningStatus(
    signingType: SigningType,
    signer: Partial<Signer>,
  ): void {
    const ddocSigningStatus: Partial<DDocSigningStatus> = {
      ddoc_certified_mode:
        signingType === 'certified' ||
        signingType === 'certified-locked',
      ddoc_locked_mode: signingType === 'certified-locked',
    };
    if (signingType === 'normal') {
      ddocSigningStatus.ddoc_use_26 = ddocSigningStatus.ddoc_use_28 =
        false;
    }
    Object.assign(signer, ddocSigningStatus);
  }

  get signingTypeDropdown(): SigningTypeDropdownItem[] {
    let labelTH = '';
    let labelEN = '';

    const list = [
      {
        label: 'ลายมือชื่ออิเล็กทรอนิกส์มาตราที่ 9',
        label_en: 'Electronic Signature Section 9',
        value: 'normal',
      },
    ];

    if (
      featureFlag.ddoc_enable_feature_26 &&
      featureFlag.ddoc_enable_feature_28
    ) {
      labelTH = '26 หรือ 28';
      labelEN = '26 or 28';
    } else if (featureFlag.ddoc_enable_feature_26) {
      labelTH = '26';
      labelEN = '26';
    } else if (featureFlag.ddoc_enable_feature_28) {
      labelTH = '28';
      labelEN = '28';
    } else {
      return list;
    }

    return list.concat([
      {
        label: `ลายมือชื่ออิเล็กทรอนิกส์มาตราที่ ${labelTH} (Signed by)`,
        label_en: `Electronic Signature Section ${labelEN} (Signed by)`,
        value: 'signed-by',
      },
      // {
      //   label: `ลายมือชื่ออิเล็กทรอนิกส์มาตราที่ ${labelTH} (Certified by, Not Locked)`,
      //   label_en: `Electronic Signature Section ${labelEN} (Certified by, Not Locked)`,
      //   value: 'certified',
      // },
      // {
      //   label: `ลายมือชื่ออิเล็กทรอนิกส์มาตราที่ ${labelTH} (Certified by, Locked)`,
      //   label_en: `Electronic Signature Section ${labelEN} (Certified by, Locked)`,
      //   value: 'certified-locked',
      // },
    ]);
  }

  get signingTypeDropdownForContract(): SigningTypeDropdownItem[] {
    let labelTH = '';
    let labelEN = '';

    const list = [
      {
        label: 'ลายมือชื่ออิเล็กทรอนิกส์',
        label_en: 'e-Signature',
        value: 'normal',
      },
    ];

    if (
      featureFlag.contract_ddoc_enable_feature_26 &&
      featureFlag.contract_ddoc_enable_feature_28
    ) {
      labelTH = '26';
      labelEN = '26';
    } else if (featureFlag.contract_ddoc_enable_feature_26) {
      labelTH = '26';
      labelEN = '26';
    } else {
      return list;
    }

    return list.concat([
      {
        label: `ลายมือชื่อดิจิทัล`,
        label_en: `Digital Signature`,
        value: 'signed-by',
      },
    ]);
  }

  get signingTypeDropdownNoneUser(): SigningTypeDropdownItem[] {
    return [
      {
        label: 'ลายมือชื่ออิเล็กทรอนิกส์มาตราที่ 9',
        label_en: 'Electronic Signature Section 9',
        value: 'normal',
      },
    ];

    // if (featureFlag.ddoc_enable_feature_26) {
    //   return list.concat([
    //     {
    //       label: 'ลายมือชื่ออิเล็กทรอนิกส์มาตราที่ 26 (Signed by)',
    //       label_en: 'Electronic Signature Section 26 (Signed by)',
    //       value: 'signed-by',
    //     },
    //     {
    //       label:
    //         'ลายมือชื่ออิเล็กทรอนิกส์มาตราที่ 26 (Certified by, Not Locked)',
    //       label_en:
    //         'Electronic Signature Section 26 (Certified by, Not Locked)',
    //       value: 'certified',
    //     },
    //     {
    //       label:
    //         'ลายมือชื่ออิเล็กทรอนิกส์มาตราที่ 26 (Certified by, Locked)',
    //       label_en:
    //         'Electronic Signature Section 26 (Certified by, Locked)',
    //       value: 'certified-locked',
    //     },
    //   ]);
    // } else {
    //   return list;
    // }
  }

  setM26onlyLastLevel(levels: LoaLevel[]) {
    const lastLevelIdx = levels.length - 1;
    levels.forEach((level, levelIdx) => {
      const ddocEnable = levelIdx === lastLevelIdx;
      level.ddoc_enable = ddocEnable;
      level.members?.forEach((member) => {
        this.setDefaultSigningStatus(member, ddocEnable);
      });
    });
  }

  get signingTypeDropdownNoneUserForContract(): SigningTypeDropdownItem[] {
    const list = [
      {
        label: 'ลายมือชื่ออิเล็กทรอนิกส์',
        label_en: 'e-Signature',
        value: 'normal',
      },
    ];

    if (featureFlag.contract_ddoc_enable_feature_26) {
      return list.concat([
        {
          label: 'ลายมือชื่อดิจิทัล',
          label_en: 'Digital Signature',
          value: 'signed-by',
        },
      ]);
    } else {
      return list;
    }
  }
}

export interface SigningTypeDropdownItem
  extends DropdownItem<string> {
  label_en: string;
}

export interface SigningSequenceStatus {
  certifyIndex: number;
  digitalSignStartIndex: number;
  error?: string;
}

export interface SigningDropdownAllowance {
  allowCertify: boolean;
  allowCertifyWithLock: boolean;
  allowNormalSign: boolean;
  allowSignedBy: boolean;
}

export type SigningType =
  | 'certified-locked'
  | 'certified'
  | 'normal'
  | 'signed-by';
