<ng-template #confirmationModal
    let-c="close">
  <app-modal-confirmation [title]="contentText()"
      [disableBtn]="actionType !== 'past-memo' && enableReason && !reason"
      (closeModal)="close()"
      (confirmModal)="submit()">
    <ng-template appCustomModalBody>
      <div *ngIf="actionType !== 'past-memo'"
          class="modal-body f-18"
          [ngClass]="actionType ==='approve' ? 'p-5' : 'py-4'">
        <div class="text-center"
            *ngIf="!enableReason">
          <span>{{"APPROVAL.CONTENT-NO-REQUIRED"|translate}}</span>
        </div>
        <div *ngIf="enableReason">
          <span>{{ reasonLabel | translate }}</span>
          <div class="form-group mt-2">
            <textarea class="form-control"
                fz-elastic
                [(ngModel)]="reason"
                (ngModelChange)="onReasonChange()">
            </textarea>
          </div>
        </div>
      </div>
      <div *ngIf="actionType === 'past-memo'" 
          class="modal-body f-18 p-5">
        <div class="text-center">
            <img src="assets/images/past-memo.png" alt="Past Memo Image">
            <div class="mt-4">
              <strong>{{ "APPROVAL.RETROSPECTIVELY-DOCUMENT" | translate }}</strong><br>
              {{ "APPROVAL.ARE-YOU-SURE-YOU-WANT-TO-APPROVE-THIS-DOCUMENT" | translate }}
            </div>
        </div>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>